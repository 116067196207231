import { env } from "md-base-tools/env";
import { GoBack } from "@/utils/tools";
export default {
  data() {
    return {
      env: env,
      isFix: 0,
      activeNames: [1],
      showTips: false,
      showSuccess: false,
      inviting: false
    };
  },
  mounted() {
    document.title = '添加客服';
  },
  methods: {
    // 是否吸顶
    handleStickyAction(is_fix) {
      this.isFix = is_fix ? 1 : 0;
    },
    //离开页面
    handleGoBack() {
      GoBack();
    }
  }
};